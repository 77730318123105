<template>
  <select class="select" v-model="proj" >
    <option value=""></option>
    <option v-for="p in projects" :value="p.name" :key="p.name">{{p.name.replace(/^https?:\/\//,'')}}</option>
  </select>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['url','project','projects']),
    proj: {
      get() {
        return this.project?.name
      },
      set(v) {
        this.$store.commit('setProject',this.projects.find(el=>el.name == v))
      }
    }
  },
  watch: {
    project(v, old) {
      if (v?.name != old?.name) {
        this.$store.commit('setTableData', null)
        this.$store.commit('setTableDates', [])
      }
    }
  }
}
</script>

<style lang="sass">
.select
  min-width: 200px
  font-size: 18px
  padding: 2px 5px
  border-radius: 5px
</style>