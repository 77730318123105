<template>
  <div class="main">
    <TopPanel/>
    <ProjectData class="main__data" v-if="project"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ProjectData from '../components/ProjectData.vue';
import TopPanel from '../components/TopPanel.vue';

export default {
  name: 'Main',
  components: { TopPanel, ProjectData },
  computed: {
    ...mapState(['project'])
  },
}
</script>
<style lang="sass">
body
  position: relative
  background: url('~@/assets/bg.webp')
  background-repeat: repeat

.main
  input, textarea, select
    outline: none
    &:focus-visible
      border-color: black

  padding: 5px
  position: relative
  min-height: 100vh
  display: flex
  flex-direction: column
  align-items: flex-start

  *
    position: relative
  &__content
    margin-top: 20px
    
  &__list
    min-width: 200px
    max-width: 300px
    margin-right: 30px
  &__viewer
    flex-grow: 1
  &__data
    align-self: stretch
    margin-left: 20px
    flex-grow: 1
</style>