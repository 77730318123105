<template>
  <div class="toggle" :class="{ 'toggle--on': modelValue, 'toggle--off': !modelValue }" @click="toggle">
    <div class="toggle__handle"></div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    toggle() {
      this.$emit('update:modelValue', !this.modelValue);
    }
  }
};
</script>

<style lang="sass">
.toggle 
  width: 50px
  height: 20px
  border-radius: 10px
  background-color: #eaeaea
  position: relative
  cursor: pointer
  outline: 2px solid rgb(150,150,150)
  &--on 
    background-color: darken(colors.$blue,20)
    outline: 2px solid rgb(250,250,250)
  &--off 
    background-color: white
    .toggle__handle
      background-color: darken(colors.$blue,20)

  &__handle 
    width: 16px
    height: 16px
    border-radius: 50%
    background-color: #fff
    position: absolute
    top: 2px
    left: 2px
    transition: transform 0.3s ease-in-out
    transform: translateX(0)
    .toggle--on & 
      transform: translateX(30px)
    
  
</style>