import { createStore } from 'vuex'
const no_group_default_value = 'Без группы'


export default createStore({
  state: {
    url: '',
    loads: {},
    tabledata: null,
    tableparticular: false,
    tableparticulardata: null,
    tabledates: [],
    tablerawdates: [],
    column: '0',
    project: null,
    projects: [],
    allusers: [],
    columntype: null,
    topCondition: false,
    group: no_group_default_value,
    Role: null
  },
  mutations: {
    setColumn(state, column) {
      state.column = column
    },
    setAllUsers(state, users) {
      state.allusers = users
    },
    setColumnType(state, columntype) {
      state.columntype = columntype
    },
    setTopCondition(state, topCondition) {
      state.topCondition = topCondition
    },
    setTableDates(state, dates) {
      state.tabledates = dates
    },
    setTableRawDates(state, dates) {
      state.tablerawdates = dates
    },
    setTableData(state, tabledata) {
      state.tabledata = tabledata
      state.tableparticular = false
      state.tableparticulardata = null
    },
    setTableParticular(state, mode) {
      state.tableparticular = mode
    },
    setTableParticularData(state, data) {
      state.tableparticulardata = data
    },
    setUrl(state, url) {
      state.url = url
    },
    toggleLoad(state, name) {
      if (state.loads[name])
        delete state.loads[name]
      else
        state.loads[name] = true
    },
    setRole(state, Role) {
      state.Role = Role
    },
    setGroup(state, value) {
      state.group = value
    },
    setProject(state, value) {
      state.project = value
      if (!value)
        state.group = no_group_default_value
      else {
        if (value.groups.includes(state.group) || (state.group === null))
          return
        state.group = value.groups[0]
      }
    },
    setProjects(state, value) {
      state.projects = value
    },
  },
  actions: {
    reset({ commit }) {
      commit('setRole', false)
    },
    async checkAuth({ state, commit }) {
      commit('toggleLoad', 'checkAuth')
      await fetch(state.url + 'auth')
        .then(res => {
          if (!res.ok) return commit('setRole', false)
          return res.text()
        }).then(role => {
          commit('setRole', role || false)
        }).catch(err => {
          console.error(err)
          commit('setRole', false)
        })
      commit('toggleLoad', 'checkAuth')
    },
    async getDates({ state, commit }, { group }) {
      await commit('toggleLoad', 'getDates')
      let availableDates = await fetch(state.url + `dates?project=${encodeURIComponent(state.project.name)}&group=${encodeURIComponent(group)}`)
        .then(async res => {
          if (!res.ok) throw (await res.text())
          return res.json()
        })
        .then(dates => Array.from(new Set(dates)).map(el => new Date(el)))
        .catch(err => { console.error(err); alert(err); })
      await commit('toggleLoad', 'getDates')
      return availableDates || []
    },
    async updateAllUsers({ state, commit }) {
      commit('toggleLoad', 'updateAllUsers')
      await fetch(state.url + 'users')
        .then(async res => {
          if (!res.ok) throw (await res.text())
          return res.json()
        })
        .then(list => {
          console.log('список всех пользователей:')
          console.log(list)
          commit('setAllUsers', list)
        })
        .catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'updateAllUsers')
    },
    async updateProjects({ state, commit }) {
      commit('toggleLoad', 'updateProjects')
      await fetch(state.url + 'projects')
        .then(async res => {
          if (!res.ok) throw (await res.text())
          return res.json()
        })
        .then(list => {
          commit('setProjects', list)
          if (state.project) {
            let found = list.find(el => el.name == state.project.name)
            console.log(found)
            commit('setProject', found || null)
          }
        })
        .catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'updateProjects')
    },
    async sendFile({ state, commit }, data) {
      commit('toggleLoad', 'sendFile')
      await fetch(state.url + 'data', {
        method: 'POST',
        body: data,
      })
        .then(async res => {
          if (!res.ok) throw (await res.text())
        })
        .catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'sendFile')
    },
    async getTableData({ state, commit }, { regexs, dates, column, group }) {
      commit('toggleLoad', 'getTableData')
      await fetch(state.url + 'getdata', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ project: state.project.name, regexs, dates, column, group })
      }).then(async res => {
        if (!res.ok) throw (await res.text())
        return res.json()
      }).then(rawdata => {

        let tabledata = {}
        let useddates = {}
        let type
        for (let line of rawdata) {
          useddates[line.date] = true
          if (group === null)
            line.phrase = JSON.stringify([line.phrase, line.group])
          if (!tabledata[line.phrase])
            tabledata[line.phrase] = {}
          if (!type && (line.value || line.value === 0) && (line.value != '-') && (line.value != '‒') && (line.value != '—')) {
            type = true
            commit('setColumnType', typeof (line.value))
          }
          tabledata[line.phrase][new Date(line.date).toLocaleDateString('fr-ch').substr(0, 5)] = line.value
        }
        if (!type) {
          type = true
          commit('setColumnType', 'string')
        }
        if ((state.columntype == 'number') && (column.toLowerCase().match('позиция')))
          commit('setTopCondition', true)
        else
          commit('setTopCondition', false)

        let rawdates = Object.keys(useddates).map(el => new Date(el)).sort((a, b) => b - a)
        useddates = rawdates.map(el => el.toLocaleDateString('fr-ch').substr(0, 5))
        commit('setGroup', group)
        commit('setTableData', tabledata)
        commit('setTableDates', useddates)
        commit('setTableRawDates', rawdates)
      }).catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'getTableData')
    },
    async getTableDateData({ state, commit }, { regexs, date, group }) {
      commit('toggleLoad', 'getTableDateData')
      await fetch(state.url + 'getdatedata', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ project: state.project.name, regexs, date, group })
      }).then(async res => {
        if (!res.ok) throw (await res.text())
        return res.json()
      }).then(rawdata => {
        commit('setTableParticularData', rawdata)

        /*
        commit('setGroup', group)
        commit('setTableData', tabledata)
        commit('setTableDates', useddates)
        commit('setTableRawDates', rawdates)
        */
      }).catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'getTableDateData')
    },
    async deleteByDate({ state, commit, dispatch }, index) {
      let areyousure = confirm('Удалить все записи проекта ' + state.project.name + ' за ' + state.tabledates[index])
      if (!areyousure) return
      commit('toggleLoad', 'deleteByDate')
      let date = state.tablerawdates[index]
      await fetch(state.url + 'data', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ date, project: state.project.name })
      }).then(async res => {
        if (!res.ok) throw (await res.text())
        commit('setTableDates', state.tabledates.filter((el, i) => i != index))
        commit('setTableRawDates', state.tablerawdates.filter((el, i) => i != index))
        await dispatch('updateProjects')
      }).catch(err => { alert(err); console.error(err) })
      commit('toggleLoad', 'deleteByDate')
    }
  },
  getters: {
    loading(state) {
      return Object.keys(state.loads).length > 0
    }
  }
})
